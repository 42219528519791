export enum EnumFormSteps {
    CPF = 1,
    DADOS_PESSOAIS = 2,
    ENDERECO_E_CONTATO = 3,
    ANEXAR_DOCUMENTO_COM_FOTO = 4,
    ANEXAR_COMPROVANTE_DE_RESIDENCIA = 5,
    TERMO_DE_RESPONSABILIDADE = 6,
    DADOS_EMPRESA = 7,
    DADOS_EMPRESA_ENDERECO = 8,
    DADOS_FATURAMENTO_COM_FOTO = 9,
    DADOS_EMPRESA_RESIDENCIA = 10,
    DADOS_EMPRESA_CONTRATO_SOCIAL = 11,
    DADOS_EMPRESA_CPF = 12,
    AGRADECIMENTO = 13,
    MENSAGEM = 14,
}