export const Theme = {
    colors:{
       verdeEscuro:'#032D35',
       VerdeAguaEscuro:'#034754',
       cinzaEsverdeado:'#384140',
       cinza: '#858585',
       cinzaClaro:'#EAEAEA',
       brancoArroxeado: '#F5F5F5',
       cinzaAzulado: '#8B8FA8',
       vermelho: '#C52328',
       branco: '#FFFFFF',
       pretoEsverdeado:'030F0D'
    }
}